import React from 'react';
import logo from './logo.svg';

import './App.css';
import Map from './components/Map.js';


function App() {

        return (
           <div className="App">
              <header className="App-header">
                <p>
                  Welcome to the LoRaWAN Map <code>Luzern</code>
                </p>
             </header>

               <Map />

            </div>
          );
   }

export default App;
