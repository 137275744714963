import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import './Map.css';

const mapStyles = {
    width: '100%',
    height: '100%'
};


export class MapContainer extends Component {

    constructor(props){
        super(props);

        this.state = {
            mapMarkers: [],
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {}
        };
    }

    onMarkerClick = (props, marker, e) =>
        this.setState(
            {
                selectedPlace: props,
                activeMarker: marker,
                showingInfoWindow: true
            }
        );

    onClose = props => {
        if(this.state.showingInfoWindow){
            this.setState({
               showingInfoWindow: false,
               activeMarker: null
            });
        }
    };

    componentDidMount(){
        fetch('https://lorawanmap.sirwechsler.com/api/getMapData.php')
            .then(response => {
                if(response.ok) return response.json();
                throw new Error('Request failed');
            })
            .then(data => {
                //console.log("ConsoleLog: " + data[0].id);
                this.setState({mapMarkers: data});
                //console.log(this.state);
            })
            .catch(error => {
                //console.log(error);
            });
    }

    getColor(rssi){
        rssi = rssi * -1;
        if(rssi <= 30){
            return '#00FF00';
        }else if ( rssi <= 35){
            return '#00FF00';
        }else if ( rssi <= 40){
            return '#00FF00';
        }else if ( rssi <= 45){
            return '#00FF00';
        }else if ( rssi <= 50){
            return '#00FF00';
        }else if ( rssi <= 55){
            return '#11FF00';
        }else if ( rssi <= 60){
            return '#22FF00';
        }else if ( rssi <= 70){
            return '#44FF00';
        }else if ( rssi <= 75){
            return '#66FF00';
        }else if ( rssi <= 80){
            return '#88FF00';
        }else if ( rssi <= 85){
            return '#99FF00';
        }else if ( rssi <= 90){
            return '#BBFF00';
        }else if ( rssi <= 95){
            return '#DDFF00';
        }else if ( rssi <= 100){
            return '#EEFF00';
        }else if ( rssi <= 105){
            return '#FFFF00';
        }else if ( rssi <= 110){
            return '#FFEE00';
        }else if ( rssi <= 115){
            return '#FFCC00';
        }else if ( rssi <= 120){
            return '#FFAA00';
        }else if ( rssi <= 125){
            return '#FF8800';
        }else if ( rssi <= 130){
            return '#FF6600';
        }else if ( rssi <= 135){
            return '#FF2200';
        }else if ( rssi > 135){
            return '#FF0000';
        }
    }


    render(){
        return(
            <Map
                google={this.props.google}
                zoom={14}
                style={mapStyles}
                initialCenter={{lat: 47.052294, lng: 8.310372}}
                >
                {this.state.mapMarkers.map(({id,receivetime,status,rssi,snr,latitude,longitude,raw_data }) => <Marker
                        onClick={this.onMarkerClick}
                        name={
                            <table className="MapInfoWindow">
                                <tr><th>Info</th><th>Wert</th></tr>
                                <tr><td>id:</td><td>{id}</td></tr>
                                <tr><td>Status:</td><td>{status}</td></tr>
                                <tr><td>Timestamp:</td><td>{receivetime}</td></tr>
                                <tr><td>RSSI:</td><td>{rssi} dBm</td></tr>
                                <tr><td>SNR:</td><td>{snr} dB</td></tr>
                                <tr><td valign="top">Gateways:</td><td>
                                    <table>
                                    {raw_data.gws.map(({gweui,rssi,snr})=> <tr><td>{gweui}</td><td>RSSI: {rssi}dBm</td><td>SNR: {snr}dB</td></tr>)}
                                    </table>
                                </td></tr>
                            </table>
                        }
                        key={id}
                        icon={{
                            //path: 'm 0 10 a 10 10 0 0 0 20 0 a 10 10 0 0 0 -20 0',
                            path: 'm 0 20 a 20 20 0 0 0 40 0 a 20 20 0 0 0 -40 0',
                            fillColor: this.getColor(rssi),
                            fillOpacity: 0.6,
                            scale: 0.5,
                            strokeWeight: 0
                        }}

                        position={{lat: latitude, lng: longitude}} />
                    )
                }


                {/*<Marker*/}
                    {/*onClick={this.onMarkerClick}*/}
                    {/*//name={this.MarkerList(this.state.mapMarkers)}*/}
                    {/*//name=<SayHello hellotext={'say hello'} />*/}
                    {/*position={{lat: 47.05210, lng: 8.310274}}*/}

                {/*/>*/}

                <InfoWindow
                 marker={this.state.activeMarker}
                 visible={this.state.showingInfoWindow}
                 onClose={this.onClose}
                >
                    <div>
                        <p>{this.state.selectedPlace.name}</p>
                    </div>
                </InfoWindow>

            </Map>
        );
    }
}


export default GoogleApiWrapper({
    apiKey: 'AIzaSyBMQ5NNk2v5zS9HsTxlfruujY_uGZhxzYs'
})(MapContainer);